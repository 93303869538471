/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useEffect, useState, Fragment } from "react";
import { Link } from "gatsby";
import scrollToElement from "scroll-to-element";
import Particles from "react-tsparticles";
import { Fade } from "react-awesome-reveal";
import LazyLoad from "react-lazyload";

import { PublicationSlider, Layout, MddNav } from "../components";
import MddForm from "../components/MddForm";
import ClientsSlider from "../components/ClientsSlider";

import locales from "../constants";

const chart_data = [
  {
    title: "Strategia[br]marketingowa",
    description:
      "Nasze strategie biorą się z 14 letniego doświadczenia na rynku marketingowym oraz specjalizacji w branży real estate. Dzięki strategii działamy w oparciu o dane, nie domysły, a wykreowane na jej podstawie marka i komunikacja, nawigowane są po Twoim świecie biznesowym najszybszą drogą do optymalnej sprzedaży.",
  },
  {
    title: "Nazwa",
    description:
      "Dlaczego warto powierzyć nam opracowane nazwy? Bo to złożony proces, który często wymaga doświadczenia. Dobra nazwa, to nie tylko dobrze brzmiące hasło, które ładnie wygląda i zadowala purystów językowych. Ważne są wywoływane emocje, adekwatność lub przemycenie właściwych wartości, gdy o dosłowności nie ma mowy. Proces wieńczy research dostępności nazwy na określonych domenach i w krajowych oraz europejskich bazach internetowych.",
  },
  {
    title: "Logo[br]i identyfikacja[br]wizualna",
    description:
      "Oferujemy kreację zarówno pojedynczych materiałów, jak i projekty kompleksowej identyfikacji wizualnej, obejmującą pełną adaptację logo i graficznego motywu przewodniego key visual w Twoich materiałach marketingowych, w tym realizacja kart mieszkań, branding placu budowy i biura sprzedaży. Zadbamy o to, aby Twój wizerunek był spójny, konsekwentny, a więc skuteczny.",
  },
  {
    title: "Hasła[br]reklamowe",
    description:
      "Wyznajemy zasadę, że jeśli reklama nie sprzedaje produktu, to nie jest kreatywna. Dobre copy to nie tylko błyskotliwa kreacja, ale przede wszystkim sprzedażowa skuteczność wynikająca z czytelności i adekwatności tekstu wobec atutów oferty oraz gustu i potrzeb Twojego odbiorcy.",
  },
  {
    title: "Serwis[br]internetowy",
    description:
      "Projektujemy serwisy internetowe, które anonimowy ruch zamieniają w tysiące wartościowych leadów dla działu sprzedaży. Nasze projekty są sumą intuicyjnej struktury, unikalnej grafiki, która wyróżnia Twoją markę na rynku, funkcjonalnych rozwiązań, jak nawigator mieszkań z formularzami kontaktowymi oraz najwyższej jakości technologii zapewniających płynną pracę i ochronę przed atakami.",
  },
  {
    title: "Social[br]media",
    description:
      "Nie uznajemy dróg na skróty, dla nas liczy się nie tylko zasięg, ale również autentyczna i kreatywna moderacja kanałów social oraz rzetelny kontakt z odbiorcą. Właśnie dlatego, w ciągu 2 lat, zbudowaliśmy społeczność 30 000 fanów hotelu w Szklarskiej Porębie na Facebooku. Co ważniejsze ruch przełożył się na realną sprzedaż inwestycji condohotelowej i popularyzację marki wśród odbiorców B2C.",
  },
  {
    title: "Materiały[br]marketingowe",
    description:
      "Pomożemy Ci w zbudowaniu szerokiej palety narzędzi marketingowych - od ulotki, przez banery do kampanii, reklamę outdoor po karty mieszkań i identyfikację Twojego biura sprzedaży.",
  },
  {
    title: "Kampania[br]inwestycyjna",
    description:
      "Dlaczego lubimy kampanie internetowe Google Ads i Facebook Ads? Bo dostarczają danych, a tam gdzie dane, tam i miejsce od ich analizy oraz optymalizacji. Gdy liczby spotykają się z naszym doświadczeniem i kreatywnością, wyniki są oszałamiające! Dzięki naszym strategiom, perfekcyjnie projektowanym materiałom i kampaniom, do tej pory udało nam się wypromować blisko 50 inwestycji deweloperskich, co przełożyło się na sprzedaż ponad 10000 mieszkań lub domów.",
  },
];

const MarketingDD = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  const particlesParams = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      size: {
        value: 3,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "bubble",
        },
        onclick: {
          enable: true,
          mode: "repulse",
        },
      },
      modes: {
        bubble: {
          distance: 250,
          duration: 2,
          size: 0,
          opacity: 0,
        },
        repulse: {
          distance: 400,
          duration: 4,
        },
      },
    },
  };

  const [isScrolled, setIsScrolled] = useState(true);

  // Initial effect
  useEffect(() => {
    const heroClouds = document.getElementById("heroClouds");
    const layerTwo = document.getElementById("heroLayerTwo");
    const layerOne = document.getElementById("heroLayerOne");
    const foreground = document.getElementById("heroFg");

    const handleScroll = () => {
      const value = window.scrollY;

      heroClouds.style.left = -value * 0.05 + "px";
      heroClouds.style.top = value * 0.05 + 40 + "px";
      layerTwo.style.top = -value * 0.15 + "px";
      layerOne.style.scale = value * 0.00001 + 1;
      layerOne.style.top = value * 0.3 + "px";
      foreground.style.top = value * 0.05 + "px";
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Spinning circle effect
  const [chartPie, setChartPie] = useState(0);
  const [chartPieAutoplay, setChartPieAutoplay] = useState(true);

  useEffect(() => {
    let interval;
    let timeout;

    clearTimeout(timeout);
    clearInterval(interval);

    if (chartPieAutoplay) {
      interval = setInterval(() => {
        setChartPie((prevPie) =>
          prevPie + 1 >= chart_data.length ? 0 : prevPie + 1
        );
      }, 7000);
    } else {
      timeout = setTimeout(() => {
        setChartPieAutoplay(true);
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [chartPieAutoplay]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 1500 ? false : true);
    });
  });

  return (
    <>
      <Layout
        seo={{
          title: "Marketing dla Deweloperów",
          href: slug,
          lang: "pl",
        }}
        header={{ icons: "#eab941" }}
        langSwitch={{
          langKey: "en",
          langSlug: "/en/marketing-for-real-estate-developers/",
        }}
      >
        <section className="container-fluid mdd-hero">
          <Fade direction="down" delay="30" triggerOnce="true">
            <h1 className="mdd-header mdd-header--1">Marketing dla</h1>
          </Fade>
          <Fade direction="down" delay="60" triggerOnce="true">
            <h1 className="mdd-header mdd-header--2">Deweloperów</h1>
          </Fade>
          <img
            src={require("../assets/img/mdd/hero/background.png")}
            className="img-fluid parallax-img"
            id="heroBackground"
            alt="Fala w tle"
          />
          <img
            src={require("../assets/img/mdd/hero/clouds.png")}
            className="img-fluid parallax-img"
            id="heroClouds"
            alt="Chmury"
          />
          <img
            src={require("../assets/img/mdd/hero/layer2.png")}
            className="img-fluid parallax-img"
            id="heroLayerTwo"
            alt="Miasto w tle"
          />
          <img
            src={require("../assets/img/mdd/hero/layer1.png")}
            className="img-fluid parallax-img"
            id="heroLayerOne"
            alt="Miasto i ludzie"
          />
          <img
            src={require("../assets/img/mdd/hero/foreground.png")}
            className="img-fluid parallax-img"
            id="heroFg"
            alt="Linia krzewów"
          />
          <button
            className="scrollPortfolio"
            onClick={() => scrollToElement("#intro")}
          />
        </section>

        <Particles
          className={`mdd-particles-wrapper${!isScrolled ? " hidden" : ""}`}
          params={particlesParams}
        />

        <section className="container-fluid mdd-section-1" id="intro">
          <div className="row">
            <div className="col-lg-5">
              <div className="inner">
                <h1>
                  Kilkadziesiąt wypromowanych inwestycji, tysiące sprzedanych
                  mieszkań
                </h1>
                <h3>
                  Specjalizujemy się w kompleksowym i efektywnym marketingu
                  inwestycji deweloperskich.
                </h3>
                <p>
                  Pomożemy Ci na każdym etapie rozwoju Twojej marki i
                  prowadzonej sprzedaży. Zbudujemy strategię odpowiadającą na
                  Twoje wyzwania wizerunkowe i sprzedażowe, stworzymy unikalną
                  kreację brandu, dzięki czemu wyróżnisz się na rynku,
                  zaprojektujemy intuicyjną i nowoczesną stronę internetową,
                  wdrożoną na najnowszych technologiach i wyposażoną w wygodną
                  wyszukiwarkę mieszkań. A jeśli Twoja marka posiada osobowość i
                  narzędzia, zrealizujemy dla Ciebie skuteczną kampanię
                  promocyjną, która obojętną aktywność Twoich odbiorców w
                  Internecie zamieni na tysiące leadów sprzedażowych.
                </p>
                <a
                  className="btn_custom"
                  type="button"
                  href="https://docs.google.com/presentation/d/1z7tzq7dPhaWSLIyh2BgVi4BR-0aGHsRyCcuF1a_rHGg/edit#slide=id.g21f27b53a76_0_0"
                  target="_blank"
                  rel="noreferrer"
                >
                  Zobacz naszą prezentację deweloperską
                </a>
              </div>
            </div>
            <div className="col-lg-5 offset-md-2">
              <LazyLoad once height={392}>
                <Fade direction="right" delay="30" triggerOnce="true">
                  <img
                    src={require("../assets/img/mdd/section_1.png")}
                    className="img-fluid"
                    alt="Mężczyzna lupa i smartphone"
                  />
                </Fade>
              </LazyLoad>
            </div>
          </div>
        </section>
        <section className="container-fluid mdd-section-2">
          <h1>Co możemy dla Ciebie zrobić?</h1>
          <h3>Wybierz kompleksowy marketing lub pojedyncze działania</h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="menunav">
                <ul className="circle">
                  {chart_data.map((item, index) => (
                    <li
                      className={`pie-chart-${index + 1} slice ${
                        chartPie !== null && chartPie === index ? "active" : ""
                      }`}
                      key={index}
                    >
                      <button
                        type="button"
                        className="circle"
                        onClick={() => {
                          setChartPie(index);
                          setChartPieAutoplay(false);
                        }}
                      >
                        <span
                          className="slice__element"
                          style={{ transform: `rotate(${20 - 45 * index}deg)` }}
                        >
                          {item.title.split("[br]").map((item, index) => (
                            <Fragment key={index}>
                              {index !== 0 ? <br /> : null}
                              {item}
                            </Fragment>
                          ))}
                        </span>
                      </button>
                    </li>
                  ))}
                  <li className="unsel circle">
                    <p className="clicky menuname">360&deg;</p>
                  </li>
                  <li className="middle circle"></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="inner">
                {chart_data
                  .filter((_, index) => chartPie !== null && chartPie === index)
                  .map((item, index) => (
                    <div className="menu-text-paragraph active" key={index}>
                      <h3>{item.title.replaceAll("[br]", " ")}</h3>
                      <p>{item.description}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid mdd-section-05">
          <div className="row align-items-lg-center">
            <div className="col-lg-4">
              <LazyLoad once height={213}>
                <Fade direction="left" delay="30" triggerOnce="true">
                  <img
                    src={require("../assets/img/mdd/paragraph.png")}
                    className="img-fluid"
                    alt="Kobieta i mężczyzna na tle sklepu internetowego"
                  />
                </Fade>
              </LazyLoad>
            </div>
            <div className="offset-lg-1 col-lg-7">
              <div className="inner">
                <h1>Dlaczego my?</h1>
                <p>
                  Ponieważ mamy ogromne doświadczenie w kompleksowej opiece
                  marketingowej inwestycji deweloperskich. Co równie ważne, po
                  prostu uwielbiamy marketing, a rynkiem nieruchomości
                  pasjonujemy się od wielu lat. Dzięki temu połączeniu osiągamy
                  fenomenalne wyniki.
                </p>
                <p>
                  Przeprowadziliśmy kilkadziesiąt zintegrowanych kampanii dla
                  uznanych deweloperów - sprawdź case studies i&nbsp;
                  <a href="#mddPortfolio">portfolio</a>.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="mdd-section-2">
          <h1>Zaufali nam</h1>
          <h3>Wiemy, jak prowadzić kampanie inwestycyjne</h3>
          <ClientsSlider />
        </section>

        <section
          id="mddPortfolio"
          className="container-fluid mdd-section-carousel"
        >
          <h1 className="nav-carousel">Szerokie portfolio</h1>
          <h3 className="nav-carousel">Sprawdź nasze realizacje</h3>
          <LazyLoad once height={583} offset={100}>
            <MddNav data={nav} locales={locales[lang]} />
          </LazyLoad>
        </section>
        <section className="container-fluid mdd-section-3" id="mddCaseStudies">
          <div>
            <h1>Udokumentowe sukcesy</h1>
            <h3>Sprawdź nasze case studies!</h3>
          </div>
          {/* Komponent ze sliderem  - zamieniz za poniższy div.row */}
          {/* <CaseStudySlider/> */}
          <LazyLoad once height={1069} offset={100}>
            <div className="row">
              <div className="col-lg-6">
                <Link to="/case-study/imperial-citi-yes">
                  <div className="mdd-image-container">
                    <img
                      src={require("../assets/img/mdd/thumbnail_1.png")}
                      className="img-fluid"
                      alt="Ogród na dachu bloku"
                    />
                    <p className="img-text">
                      <span>90 umów w</span>
                      <br />
                      <span>90 dni</span>
                    </p>
                  </div>
                </Link>
                <div className="inner">
                  <Link to="/case-study/imperial-citi-yes">
                    <h4 className="when-link">
                      Case study kampanii Google i Facebook Ads inwestycji
                      Imperial Citi Yes
                    </h4>
                  </Link>
                  <p>
                    Specjalizujemy się w kompleksowym marketingu inwestycji
                    deweloperskich, bo uwielbiamy marketing i interesujemy się
                    rynkiem RE. Przeprowadziliśmy kilkadziesiąt zintegrowanych
                    kampanii dla uznanych deweloperów. Dbamy o odpowiedni
                    wizerunek inwestycji i marki dewelopera, ale przede
                    wszystkim zależy nam na wymiernej skuteczności prowadzonych
                    działań. Właśnie dlatego pomogliśmy naszym Klientom w
                    sprzedaży ponad 5000 jednostek
                  </p>
                  <Link
                    to="/case-study/imperial-citi-yes"
                    className="btn_custom"
                  >
                    Zobacz więcej
                  </Link>
                </div>
              </div>

              <div className="col-lg-6">
                <Link to="/projekty/imperial-stawowa-residence/">
                  <div className="mdd-image-container">
                    <img
                      src={require("../assets/img/mdd/thumbnail_2.png")}
                      className="img-fluid"
                      alt="Samochód przed nowoczesnym domem"
                    />
                    <p className="img-text">
                      <span>120 mieszkań</span>
                      <br />
                      <span>100% realizacji</span>
                      <br />
                      <span>celów!</span>
                    </p>
                  </div>
                </Link>
                <div className="inner">
                  <Link to="/projekty/imperial-stawowa-residence/">
                    <h4 className="when-link">
                      Google i Facebook Ads dla 3 etapów inwestycji
                      mieszkaniowej Imperial Stawowa Residence
                    </h4>
                  </Link>
                  <p>
                    Specjalizujemy się w kompleksowym marketingu inwestycji
                    deweloperskich, bo uwielbiamy marketing i interesujemy się
                    rynkiem RE. Przeprowadziliśmy kilkadziesiąt zintegrowanych
                    kampanii dla uznanych deweloperów. Dbamy o odpowiedni
                    wizerunek inwestycji i marki dewelopera, ale przede
                    wszystkim zależy nam na wymiernej skuteczności prowadzonych
                    działań. Właśnie dlatego pomogliśmy naszym Klientom w
                    sprzedaży ponad 5000 jednostek
                  </p>
                  <Link
                    to="/projekty/imperial-stawowa-residence/"
                    className="btn_custom"
                  >
                    Zobacz więcej
                  </Link>
                </div>
              </div>
            </div>
          </LazyLoad>
        </section>

        <section className="container-fluid mdd-section-4">
          <h1>Publikacje branżowe</h1>
          <LazyLoad once height={885} offset={100}>
            <PublicationSlider />
          </LazyLoad>
          {/* <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-4">
                    <img
                      src={require("../assets/img/mdd/publication_1.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-8 publication-col">
                    <h6>
                      Magazyn Inmag. Numer 1(3)/2020
                    </h6>
                    <h4>
                      Siła optymalizacji. Kampanie Google Ads dla inwestycji deweloperskich
                    </h4>
                    <a
                      href="https://wp.adream.pl/wp-content/themes/adream/publications/inmag-01-2020.pdf"
                      className="btn_custom"
                    >
                        Pobierz publikację
                    </a>
                  </div>
                </div>
                <p className="publication-paragraph">
                  “ […] ponad 1000 zapytań na minutę w Google dotyczy właśnie nieruchomości. W czasach tak łatwego dostępu do informacji, jakie zapewnił internet, deweloperzy prześcigają się w działaniach reklamowych – rezygnując powoli z działań offline na rzecz kanałów mierzalnych, uzyskujących konkretne dane. To właśnie te dane, tak jak w przypadku reklamy Google Ads, stanowią o potencjalne kampanii reklamowych online, ponieważ tam gdzie są dane, tam pojawia się pole do ich analizy i popartej statystykami poprawy prowadzonych działań.”
                </p>

                <p className="publication-signature">
                  <strong>
                    Patrycja Idzińska
                  </strong>
                  <br/>
                  CEO Agencji WHEN
                </p>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-4">
                    <img
                      src={require("../assets/img/mdd/publication_2.png")}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col-8 publication-col">
                    <h6>
                      Magazyn Inmag. Numer 3(5)/2020
                    </h6>
                    <h4>
                      Kreatywność w służbie sprzedaży. Jak wyróżnić się na tle konkurencji i dać się zapamiętać?
                    </h4>
                    <Link
                      to="/blog/branding/kreatywnosc-w-sluzbie-sprzedazy-czyli-nasz-artykul-dla-magazynu-inmag/"
                      className="btn_custom"
                    >
                        Pobierz publikację
                    </Link>
                  </div>
                </div>
                <p className="publication-paragraph">
                  “ Od lat buduje się dużo, inwestycja goni inwestycję, a klienci często gubią się w gąszczu dostępnych ofert. Przeglądając ogłoszenia wystawiane przez biura sprzedaży czy też obcując z marką w przestrzeni miejskiej, nie trudno o to, aby inwestycja została pominięta. Co wpływa na przyciągnięcie uwagi? Jak dać się zapamiętać i wytworzyć w kupujących emocje, które sprawią, że to właśnie Twoja inwestycja stanie się obiektem pożądania?  ”
                </p>

                <p className="publication-signature">
                  <strong>
                    Wojciech Kmiecik
                  </strong>
                  <br/>
                  Creative Director ADream
                </p>
              </div>
            </div> */}
        </section>
        <section className="container-fluid mdd-contact-form">
          <div className="row">
            <div className="col-md-4">
              <h1>Porozmawiajmy o sukcesie Twojej inwestycji</h1>
              <p>
                Zostaw swoje dane, a nasz specjalista skontaktuje się z Tobą.
              </p>
              <p>
                Specjalizujemy się w kompleksowym marketingu inwestycji
                deweloperskich, bo uwielbiamy marketing i interesujemy się
                rynkiem RE.
              </p>
              <p>
                Właśnie dlatego pomogliśmy naszym Klientom w sprzedaży niemal
                10000 jednostek.
              </p>
            </div>
            <div className="col-md-8">
              <MddForm locales={locales[lang]} lang={lang} />
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default MarketingDD;
